:root {
    // Algunos estilos de material solo admiten estas variables, no las de styles_primitives.scss (ni siquiera asignar aquí en el root esas variables)
    --primary-50: #FCFCFD;
    --primary-300: #B8C5D6;
    --primary-800: #424B57;
    --primary-900: #02182b;
}


//#region overlay (general dialogs y elementos con fondo opaco tipo popup)
.cdk-overlay-container {
    z-index: 11;
}

.cdk-overlay-container:not(:has(.mat-mdc-select-panel)) {
    z-index: 1031;
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
    overflow: auto;
}

//#endregion

//#region MatFormField
.mat-mdc-form-field {
    width: 100%;
    margin-bottom: 1.25rem;
    font-family: map-get($font-family, secondary) !important;
    --mdc-outlined-text-field-hover-outline-color: var(--primary-900);
    --mdc-outlined-text-field-focus-outline-color: var(--primary-900);
    --mdc-outlined-text-field-error-outline-color: #F10A0A;
    --mdc-outlined-text-field-error-focus-outline-color: #F10A0A;
    --mdc-outlined-text-field-error-hover-outline-color: #F10A0A;
}

.mat-mdc-form-field-focus-overlay {
    background-color: inherit;
}

.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix {
    padding: 0 5px !important;
}

.mdc-form-field>label {
    padding-left: 10px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $primary-50 !important;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-family: map-get($font-family, secondary) !important;
    font-weight: map-get($font-weight, medium) !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.5px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-notch-piece .mdc-floating-label {
    font-weight: 600 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused .mdc-floating-label {
    color: $primary-900 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--invalid .mdc-floating-label,
.mat-form-field-invalid .mat-mdc-form-field-icon-suffix {
    color: $semantic-error-400 !important;
}

//placeholder p2-regular
.mdc-text-field {

    input::placeholder,
    textarea::placeholder {
        font-family: map-get($font-family, secondary) !important;
        font-weight: map-get($font-weight, regular) !important;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.5px !important;
    }

    //oculta placeholder al hacer click en el input
    &.mdc-text-field--focused input::placeholder,
    &.mdc-text-field--focused textarea::placeholder {
        color: transparent !important;
    }
}


//mat-hint p3-regular
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
    font-family: map-get($font-family, secondary) !important;
    font-weight: map-get($font-weight, regular) !important;
    font-size: 12px !important;
    line-height: 18px !important;
    letter-spacing: 0.5px !important;
    margin: 0px 0px 8px 0px !important;
}

// El texto del input p2-medium
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
    font-family: map-get($font-family, secondary);
    font-weight: map-get($font-weight, medium);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
}

.mat-mdc-form-field-error,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--invalid .mdc-floating-label {
    color: $semantic-error-400 !important;
    font-family: map-get($font-family, secondary);
    font-weight: map-get($font-weight, regular);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
}

//#endregion


//#region MatRadioButton
.mat-mdc-radio-button .mdc-form-field {
    font-family: map-get($font-family, secondary);
}

.mat-mdc-radio-button.mat-accent {
    --mat-radio-ripple-color: tranparent;
    --mat-radio-checked-ripple-color: transparent;
}

.mdc-radio__background {

    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
        border-color: $primary-900 !important;
    }
}

.mat-mdc-radio-button .mdc-radio {
    --mdc-radio-state-layer-size: 30px;

    @media (max-width: 767.98px) {
        --mdc-radio-state-layer-size: 20px;
    }
}

//#endregion

//#region MatCheckBox
.mat-accent.mat-mdc-checkbox,
.mat-mcd-checkbox {
    display: block !important;
    --mat-checkbox-label-text-color: var(--primary-800);
    --mdc-checkbox-state-layer-size: 22px;
}

.mdc-checkbox .mdc-checkbox__background {
    border-color: $primary-900 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:disabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background: $primary-900 !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
    display: none !important;
}

.mat-accent.mat-mdc-checkbox,
.mat-mcd-checkbox {

    &.cb-consentimiento,
    &.cb-info-adicional {
        .mat-internal-form-field {
            font-weight: map-get($font-weight, medium);
            font-family: map-get($font-family, secondary);
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
        }
    }

    &.cb-consentimiento2 {
        .mat-internal-form-field {
            align-items: start !important;
            font-weight: map-get($font-weight, regular);
            font-family: map-get($font-family, secondary);
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.5px;
        }
    }

    a {
        color: $primary-900;
        font-weight: map-get($font-weight, semibold);
        text-decoration-line: underline;
    }

    a:hover {
        opacity: 0.7;
    }
}

//#endregion

//#region MatSelect
.mat-mdc-select,
.mat-mdc-option {
    font-family: map-get($font-family, secondary) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.5px !important;
}

.mat-mdc-option.mat-mdc-option-active {
    background-color: transparent !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: $primary-100 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    font-weight: 600 !important;
    color: $primary-900 !important;
}

div.mat-mdc-select-panel {
    background-color: $neutral-0 !important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
    color: $primary-900 !important;
}

.mat-mdc-option-multiple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-mdc-option-multiple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background-color: $primary-900 !important;
}

.mat-mdc-select-arrow {
    color: $primary-900 !important;
}

//#endregion

//#region MatAutoComplete
div.mat-mdc-autocomplete-panel {
    background: #ffffff !important;
}

//#endregion

//#region MatSlideToggle
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch {
    --mdc-switch-selected-track-color: var(--primary-900);
    --mdc-switch-selected-hover-track-color: var(--primary-900);
    --mdc-switch-selected-focus-track-color: var(--primary-900);
    --mdc-switch-selected-pressed-track-color: var(--primary-900);
    --mdc-switch-selected-focus-handle-color: #ffffff;
    --mdc-switch-selected-pressed-handle-color: #ffffff;
    --mdc-switch-track-height: 24px;
    --mat-switch-with-icon-handle-size: 18px;
    --mdc-switch-state-layer-size: 24px;
    --mat-switch-selected-with-icon-handle-horizontal-margin: 0 30px;
}

.mat-mdc-slide-toggle .mdc-form-field>label {
    padding-left: 4px !important;
}

//#endregion

//#region MatDatePicker
.mat-calendar,
.mat-calendar-body-cell-content,
.mat-mdc-button {
    font-family: map-get($font-family, secondary) !important;
}

.mat-datepicker-content {
    background-color: $primary-50 !important;
    border: 1px solid $primary-400 !important;
    border-radius: 4px !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: $primary-900 !important;
}

.mat-calendar-body-selected {
    background-color: $primary-900 !important;
    color: $neutral-0 !important;
}

//#endregion

//#region MatExpansionPanel
//TODO revisar si no cambia el color en los acordeones que tienen otro color
.mat-expansion-panel-header-title {
    color: $primary-900 !important;
}

.mat-expansion-panel:not([class*=menu-accordion]) {
    .mat-expansion-panel-content {

        p:not(.p1-regular):not(.p1-semibold):not(.p1-semiboldit):not(.p2-medium):not(.p1-bold):not(.p2-regular):not(.p2-medium):not(.p2-bold):not(.p3-light):not(.p3-regular):not(.p3-medium):not(.p3-semibold),
        //span:not(.p1-regular):not(.p1-semibold):not(.p1-semiboldit):not(.p2-medium):not(.p1-bold):not(.p2-regular):not(.p2-medium):not(.p2-bold):not(.p3-light):not(.p3-regular):not(.p3-medium):not(.p3-semibold):not(.material-symbols-outlined),
        ul li:not(.p1-regular):not(.p1-semibold):not(.p1-semiboldit):not(.p2-medium):not(.p1-bold):not(.p2-regular):not(.p2-medium):not(.p2-bold):not(.p3-light):not(.p3-regular):not(.p3-medium):not(.p3-semibold),
        ol li:not(.p1-regular):not(.p1-semibold):not(.p1-semiboldit):not(.p2-medium):not(.p1-bold):not(.p2-regular):not(.p2-medium):not(.p2-bold):not(.p3-light):not(.p3-regular):not(.p3-medium):not(.p3-semibold),
        a:not(.link1-semibold):not(.link1-bold):not(.link2-medium):not(.link2-semibold):not(.link2-bold):not(.p2-medium):not(.link3-semibold):not(.link3-bold) {
            //P1-regular
            font-family: map-get($font-family, secondary);
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
        }

        strong {
            font-weight: map-get($font-weight, bold);
        }
    }

    .mat-expansion-panel-header {
        min-height: 60px !important;
        height: auto !important;

        @media(max-width: 767.98px) {
            padding: 10px 16px !important;
        }
    }
}

.mat-expansion-panel:not([class*=menu-accordion]):not([class*=faqs-accordion]:not([class*=mat-elevation-z])) {
    --mat-expansion-container-shape: 8px;
    border-radius: 8px !important;
    box-shadow: none !important;
    background: $neutral-0 !important;

    &.mat-expanded {
        box-shadow: 0px 1px 4px 0px rgba(2, 24, 43, 0.15) !important;
    }

    .mat-expansion-panel-body {
        padding: 16px 24px !important;

        @media(max-width: map-get($responsive-breakpoints, xs)) {
            padding: 16px !important;
        }
    }

    .mat-expansion-panel-header {
        border: 1px solid $primary-300 !important;
    }

    .mat-expansion-panel-header:hover {
        background: $primary-100 !important;
    }

    .mat-expansion-panel-header.mat-expanded {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .mat-expansion-indicator svg {
        $size: 24px !important;
        width: $size;
        height: $size;
        fill: $primary-900 !important;
    }

    .mat-expansion-panel-content {
        background: $primary-75;
        border: 1px solid $primary-300 !important;
        border-top: 0px !important;
        border-top-left-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }


}

#menu-mobile .menu-accordion {
    box-shadow: none;

    .menu-expansion-panel-header {
        height: 38px;

        &.disabled-hover:hover {
            background: inherit !important;
        }
    }

    .mat-expansion-panel-body {
        padding: 0px !important;
    }
}

//#endregion

//#region MatPaginator
// .p2-medium
.mat-mdc-paginator-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center;

    line-height: 20px;
    color: $primary-500 !important;
    font-family: map-get($font-family, secondary) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: map-get($font-weight, medium) !important;
    letter-spacing: 0.56px !important;

    .mat-mdc-icon-button.mat-mdc-button-base svg {
        fill: $primary-500;
    }

    .mat-mdc-icon-button[disabled][disabled]svg {
        fill: $primary-300;
    }

    @media (min-width: 991.98px) {
        justify-content: end !important;
    }
}

.mat-mdc-paginator-page-size {
    @media (max-width: 767.98px) {
        display: none !important;
    }
}

.mat-mdc-paginator-range-label {
    margin-right: 20px !important;
    margin-left: 10px;
}

//#endregion

//#region MatTabs
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: var(--primary-900);
    --mat-tab-header-active-label-text-color: var(--primary-900);
    --mat-tab-header-active-ripple-color: var(--primary-900);
    --mat-tab-header-inactive-ripple-color: var(--primary-900);
    --mat-tab-header-active-focus-label-text-color: var(--primary-900);
    --mat-tab-header-active-hover-label-text-color: var(--primary-900);
    --mat-tab-header-active-focus-indicator-color: var(--primary-900);
    --mat-tab-header-active-hover-indicator-color: var(--primary-900);
}

.mat-tab-group-with-mb-scroll {
    @media (max-width: 991.98px) {
        .mat-mdc-tab-header {
            overflow-x: scroll !important;
        }

        .mat-mdc-tab-header::-webkit-scrollbar {
            display: none;
        }

        .mat-mdc-tab-label-container {
            overflow: visible !important;
        }

        .mat-mdc-tab-header-pagination {
            display: none !important;
        }
    }
}

.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    --mat-tab-header-label-text-font: "Poppins";
    --mat-tab-header-label-text-size: 16px;

    .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
        color: $primary-400;
    }

    .mdc-tab--active .mdc-tab__text-label {
        color: $primary-900;
    }

    .mdc-tab--active .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: $primary-900 !important;
    }
}

.mat-mdc-tab-body {
    li {
        margin-bottom: 1rem !important;
    }

    img {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
    }

    //FIX para que en mobile no se quede el width:xx% del editor
    .image.image_resized {
        @media (max-width: 991.98px) {
            width: auto !important;
        }
    }
}

.segofinance-tab {
    .mat-mdc-tab-header .mdc-tab--active .mdc-tab__text-label {
        color: $primary-900 !important;
        font-weight: 600 !important;
    }

    .mat-mdc-tab-header .mdc-tab--active .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: $primary-900 !important;
    }
}

.venture-tab {
    .mat-mdc-tab-header .mdc-tab--active .mdc-tab__text-label {
        color: $secondary-venture-600 !important;
        font-weight: 600 !important;
    }

    .mat-mdc-tab-header .mdc-tab--active .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: $secondary-venture-600 !important;
    }
}

.factoring-tab {
    .mat-mdc-tab-header .mdc-tab--active .mdc-tab__text-label {
        color: $secondary-factoring-600 !important;
        font-weight: 600 !important;
    }

    .mat-mdc-tab-header .mdc-tab--active .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: $secondary-factoring-600 !important;
    }
}

.realestate-tab {
    .mat-mdc-tab-header .mdc-tab--active .mdc-tab__text-label {
        color: $secondary-estate-500 !important;
        font-weight: 600 !important;
    }

    .mat-mdc-tab-header .mdc-tab--active .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: $secondary-estate-500 !important;
    }
}

.funds-tab {
    .mat-mdc-tab-header .mdc-tab--active .mdc-tab__text-label {
        color: $secondary-funds-600 !important;
        font-weight: 600 !important;
    }

    .mat-mdc-tab-header .mdc-tab--active .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: $secondary-funds-600 !important;
    }
}

.creative-tab {
    .mat-mdc-tab-header .mdc-tab--active .mdc-tab__text-label {
        color: $secondary-creative-500 !important;
        font-weight: 600 !important;
    }

    .mat-mdc-tab-header .mdc-tab--active .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: $secondary-creative-500 !important;
    }
}

#comparacion-indexadas,
#comparacion-tematicas {
    .mat-mdc-tab-header .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
        font-family: map-get($font-family, primary) !important;
        font-weight: 600 !important;
        font-size: 18px !important;
        letter-spacing: 0.05em !important;
    }
}

.mat-mdc-tab-body {
    a:not(.btn, .btn-primary, .btn-secondary) {
        color: $primary-800 !important;
        font-size: initial;
        text-decoration: underline;
    }

    a:not(.btn, .btn-primary, .btn-secondary):hover {
        opacity: 0.7;
    }

    img {
        max-width: 100% !important;
    }

    p,
    // div span:not(.material-symbols-outlined),
    ul li,
    ol li,
    a {
        //p2-regular
        font-family: map-get($font-family, secondary);
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
    }

    p,
    ul li,
    ol li {
        margin: 0px 0px 10px 0px;
    }

    div,
    span {
        margin: 0px;
    }

    strong {
        font-weight: map-get($font-weight, bold);
    }
}

//#endregion

//#region MatTooltip
.mat-mdc-tooltip-panel-above {
    transform: translateY(-30px) !important;
}

.mat-mdc-tooltip {
    border-radius: 8px !important;
}

.mdc-tooltip__surface {
    background-color: $neutral-0 !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 8px 0px rgba(2, 24, 43, 0.15);
    color: $neutral-800 !important;
    font-family: map-get($font-family, secondary) !important;
    font-size: 14px !important;
    line-height: 18px !important;
    padding: 14px 24px !important;
    max-width: 400px !important;

    @media (max-width: 576.98px) {
        max-width: 300px !important;
    }
}

.sego-tooltip .mdc-tooltip__surface {
    background-color: $primary-900 !important;
    color: $neutral-0 !important;
}

.venture-tooltip .mdc-tooltip__surface {
    background-color: $secondary-venture-100 !important;
}

.factoring-tooltip .mdc-tooltip__surface {
    background-color: $secondary-factoring-100 !important;
}

.realestate-tooltip .mdc-tooltip__surface {
    background-color: $secondary-estate-100 !important;
}

.funds-tooltip .mdc-tooltip__surface {
    background-color: $secondary-funds-100 !important;
}

.creative-tooltip .mdc-tooltip__surface {
    background-color: $secondary-creative-100 !important;
}

.mat-mdc-menu-panel.mat-menu-tooltip {
    max-width: 290px;

    // MatMenu usado como si fuese un tooltip
    .title {
        font-family: map-get($font-family, secondary);
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: $primary-900;
    }

    .texto {
        font-family: map-get($font-family, secondary);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
}

.mat-mdc-menu-panel {
    background: $primary-50 !important;
}

.mat-mdc-menu-content {
    line-height: 20px !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    --mat-icon-button-state-layer-color: transparent;
}

.mat-elevation-z2,
.mat-mdc-elevation-specific.mat-elevation-z2 {
    box-shadow: 0px 8px 24px 0px rgba(2, 24, 43, 0.15);
}

//#endregion

//#region MatMenu

.btn-filtro.mat-mdc-button .mdc-button__label {
    width: 100% !important;
}

$min-width-eav-mis-inversiones: 276px;

#mis-inversiones-creative {
    .btn-filtro {
        @media (min-width: map-get($responsive-breakpoints, md)) {
            width: $min-width-eav-mis-inversiones;
        }
    }
}

.inversiones-filter-menu {
    min-width: $min-width-eav-mis-inversiones !important;

    .mat-mdc-menu-item:not([disabled]):hover {
        background-color: $primary-100;
    }
}

.menuFiltroEstado {
    &.mat-mdc-menu-panel {
        min-width: 290px !important;
        border: 1px solid $primary-300 !important;

        @media(max-width:991.98px) {
            min-width: auto !important;
        }

        button {
            border-bottom: 1px solid $primary-300 !important;
        }

        .filtro-activa,
        .filtro-resetear {
            color: #637182;
        }

        .filtro-fallida {
            color: #EC6082;
        }

        .filtro-desinvertidas {
            color: #89D086;
        }
    }
}

//#endreigon

//#region MatDialog
.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0.75rem 1.25rem;
    border-radius: 8px;
    --mdc-dialog-container-color: var(--primary-50);

    @media (max-width: 767.98px) {
        border-radius: 0px;
    }
}

.dialog-sin-padding .mdc-dialog__surface {
    padding: 0;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    font-weight: 400 !important;
    line-height: 20px !important;
    font-size: 14px !important;
    font-family: "Montserrat" !important;
    letter-spacing: 0 !important;
}


.bg-primary-300,
.bg-secondary-venture-300,
.bg-secondary-venture-400,
.bg-secondary-factoring-300,
.bg-secondary-factoring-400,
.bg-secondary-funds-200,
.bg-secondary-funds-300,
.bg-secondary-funds-400,
.bg-secondary-estate-100,
.bg-secondary-estate-400 {
    .mat-mdc-dialog-surface {
        background-color: inherit !important;
    }
}

.cdk-overlay-pane.mat-mdc-dialog-panel,
.cdk-overlay-pane.dialog-tarifas,
.cdk-overlay-pane.dialog-mb-w100 {
    max-width: 100vw !important;

    @media (max-width: 767.98px) {
        height: 100%;
        width: 100%;
    }

    .mat-dialog-container {
        max-height: 100vh;
    }


}

.cdk-overlay-pane.dialog-menu {
    height: 100%;
    width: 100%;
    max-width: 100vw !important;

    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 0px;
        max-height: 100vh;
    }
}

.dialog-w50-md-w100 {
    max-width: 50vw !important;

    @media (max-width: 767.98px) {
        max-width: 100vw !important;
        height: 100%;
        width: 100%;
    }
}

.dialog-detalles-operacion {
    width: 1000px;
    max-width: 90vw !important;
    overflow-y: auto;
}

.dialog-scroll {
    overflow-y: auto;
    max-height: calc(100vh - 50px);
}

.dialog-scroll::-webkit-scrollbar {
    @media (max-width: 761px) {
        display: none;
    }
}

//#endregion

//#region MatBottomSheetRef
.mat-bottom-sheet-container {
    padding: 0 !important;
}

.mat-bottom-sheet-container {
    border-radius: 8px 8px 0px 0px !important;
}

.custom-bottomSheet-container {
    border-radius: 8px 8px 0px 0px;
}

//#endregion

//#region MatTable
.mat-mdc-table td:not(.mat-column-expandedDetail):not(.mat-column-masInfo) {
    height: 60px;
}

.mat-mdc-table th,
.mat-mdc-table td:not(.mat-column-expandedDetail):not(.mat-column-masInfo) {
    //TODO: Quitar este important cuando quitemos los px de todas las tablas @LSegovia
    padding: 0 12px !important;
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-header-row,
.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-table .mat-mdc-header-cell {
    border-color: $primary-300;
}

.mat-mdc-cell {
    --mat-table-row-item-outline-color: var(--primary-300);
}
//#endregion


//#region MatIcon
@for $i from 10 through 140 {
    .mat-icon-size-#{$i} {
        $size: #{$i}px !important;
        font-size: $size;
        height: $size;
        width: $size;
    }

    @if($i >=24) {
        $i: $i + 2;
    }
}

.mat-icon-size-125 {
    $size: 125px !important;
    font-size: $size;
    height: $size;
    width: $size;
}

.mat-icon-size .mat-icon-aligntext {
    display: inline-flex;
    vertical-align: text-bottom;
}

.mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    line-height: 35px;
    border-radius: 50%;
}

.filled {
    font-variation-settings: 'FILL' 1;
}

.material-symbols-outlined {
    overflow: hidden;
}

//#endregion

//#region MatDatepicker{
.mat-datepicker-toggle-active {
    color: $primary-900 !important;
}

//#region MatSnackBar
.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
        background-color: $primary-50 !important;
        border-radius: 8px !important;
        box-shadow: none !important;
        border: 1px $primary-400 solid;
    }

    .mdc-button {
        min-width: 40px;

        .mdc-button__label {
            top: -2px;
        }
    }

    .mat-mdc-button {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: $primary-900 !important;
    }

    .mdc-snackbar__label {
        color: $primary-900 !important;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        font-family: map-get($font-family, primary) !important;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
    }

    &.sego-snackbar-success {
        .mdc-snackbar__label {
            border-left: solid 10px $semantic-success-400;
        }
    }

    &.sego-snackbar-error {
        .mdc-snackbar__label {
            border-left: solid 10px $semantic-error-400;
        }
    }
}

//#endregion

//#region MatStepper
.mat-stepper-horizontal,
.mat-stepper-vertical {
    background: transparent !important;
}

.mat-step-header .mat-step-label {
    font-family: map-get($font-family, primary);
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 1px;
}

//#endregion

//#region Registro
#registro {
    .mat-checkbox-layout .mat-checkbox-label {
        white-space: break-spaces;
        padding-top: 5px;
        text-align: left;
    }

    .mat-horizontal-stepper-header-container {
        background: #F0F2F4;
        margin-bottom: 3rem;
        border-radius: 4px;
        padding: 0;

        @media (max-width: 991.98px) {
            margin-bottom: 2rem;
        }
    }

    .mat-horizontal-stepper-header {
        height: 60px;

        @media (max-width: 991.98px) {
            padding: 0 14px;
        }
    }

    .mat-stepper-horizontal-line {
        border-top-color: $primary-300;
        border-top-width: 2px;
        margin: 0px;
    }

    .line-primary-900 .mat-stepper-horizontal-line {
        border-top-color: $primary-900;
    }

    .mat-step-header:hover,
    .mat-step-header:focus {
        background-color: inherit !important;
    }

    .mat-step-icon {
        $size: 34px;
        height: $size;
        width: $size;
        font-size: 16px;
        line-height: 24px;
    }

    .mat-step-header .mat-step-icon-state-number {
        background-color: $primary-300;
        color: $primary-900;
        border: 2px solid $primary-300;
        font-weight: 700;
    }

    .mat-step-header .mat-step-icon-selected {
        background-color: white;
        color: $primary-900;
        border: 2px solid $primary-900;
        font-weight: 700;
    }

    .mat-step-header .mat-step-icon-state-edit {
        background-color: black;
        color: white;
        border: 2px solid black;
    }

    .mat-step-header .mat-step-label {
        font-family: map-get($font-family, primary);
        font-size: 14px;
        line-height: 24px;
        color: $primary-900;

        @media (max-width: 991.98px) {
            font-size: 14px;
        }

        @media (max-width: 767.98px) {
            display: none;
        }
    }

    .mat-step-header .mat-step-label.mat-step-label-active {
        font-weight: 700;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: $primary-900;
    }

    .mat-form-field-infix {
        font-family: map-get($font-family, secondary) !important;
        font-weight: 500 !important;
        letter-spacing: 0.04em;
    }

}

.selectLineaRegistro {
    .tabs .mat-mdc-optgroup-label .mdc-list-item__primary-text {
        font-family: map-get($font-family, primary);
        font-weight: map-get($font-weight, semibold);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
    }

    .mat-mdc-option.mdc-list-item {
        line-height: 20px;
    }
}

//#endregion

//#region Login
#inicioSesion,
#tfaSMS,
#tfaError {
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: $primary-900;
    }

    .mat-form-field-infix {
        font-family: map-get($font-family, secondary) !important;
        font-weight: 500 !important;
        letter-spacing: 0.04em;
    }

    .mat-input-element {
        font-size: 16px;
        line-height: 24px;
        font-family: map-get($font-family, secondary) !important;
        font-weight: 500;
    }
}

//#endregion

//region MatSpinner / MatProgressBar
$colors: (
    neutral-0: $neutral-0,
    neutral-1000: $neutral-1000,
    primary-800: $primary-800,
    primary-500: $primary-500,
    primary-400: $primary-400,
    primary-200: $primary-200,
    primary-50: $primary-50,
    segofinance: $primary-900,
    venture: $secondary-venture-500,
    realestate: $secondary-estate-500,
    factoring: $secondary-factoring-500,
    funds: $secondary-funds-500,
    creative: $secondary-creative-400,
    venture-redireccion: $secondary-venture-700,
    realestate-redireccion: $secondary-estate-500,
    factoring-redireccion: $secondary-factoring-600,
    funds-redireccion: $secondary-funds-700,
    creative-redireccion: $secondary-creative-500
);

$track-colors: (
    track-neutral-0: $neutral-0,
    track-primary-50: $primary-50,
    track-primary-200: $primary-200,
    track-primary-500: $primary-500,
    track-primary-800: $primary-800,
);

@each $name, $color in $colors {
    .mat-mdc-progress-spinner.#{$name} {
        --mdc-circular-progress-active-indicator-color: #{$color} !important;
    }

    .mat-mdc-progress-bar.#{$name}[mode*=indeterminate] {
        --mdc-linear-progress-active-indicator-color: #{$color} !important;
    }

    @each $track-name, $track-color in $track-colors {
        .mat-mdc-progress-bar.#{$name}.#{$track-name}[mode*=indeterminate] {
            --mdc-linear-progress-active-indicator-color: #{$color} !important;
            --mdc-linear-progress-track-color: #{$track-color} !important;
        }
    }

    .mat-mdc-progress-bar.#{$name}[mode*=buffer] {
        --mdc-linear-progress-track-color: #{$color} !important;
    }
}


@each $name, $color in $colors {
    .loading-text-#{$name} {
        color: $color;

        .dots {
            letter-spacing: 0.1rem !important;
        }

        &.dots {
            letter-spacing: 0.5rem !important;
        }
    }
}

//#endregion

//#region MatSort
.inversiones-sort-container .mat-sort-header-container {
    justify-content: end;
}

.mat-sort-header-arrow {
    opacity: 1 !important;
    transform: translateY(0px) !important;
    color: $primary-900 !important;
}

//#endregion

//#region MatButton
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0 !important;
}

.mat-ripple {
    display: none;
}

//#endregion